import { useState, useEffect } from 'react';
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import logo from '../assets/img/logo.png';
import linkedinIcon from '../assets/img/linkedinIcon.svg';
import instagramIcon from '../assets/img/instagramIcon.svg';
import github from '../assets/img/github.svg';

export const NavBar = () => {
  const [activeLink, setActiveLink] = useState('home');
  const [scrolled, seScrolled] = useState(false);

  useEffect(() => {
    const onScroll = () => {
      if (window.scrollY > 50) {
        seScrolled(true);
      } else {
        seScrolled(false);
      }
    };
    window.addEventListener('scroll', onScroll);
    return () => window.removeEventListener('scroll', onScroll);
  }, []);

  const onUpdateActiveLink = (value) => {
    setActiveLink(value);
  };

  return (
    <Navbar expand="lg" className={scrolled ? 'scrolled' : ''}>
      <Container>
        <Navbar.Brand href="#home">
          <img src={logo} alt="Logo" />
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav">
          <span className="navbar-toggler-icon"> </span>
        </Navbar.Toggle>
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="me-auto">
            <Nav.Link
              href="#home"
              className={
                activeLink === 'home' ? 'active navbar-link' : 'navbar-link'
              }
              onClick={() => onUpdateActiveLink('home')}
            >
              Home
            </Nav.Link>
            <Nav.Link
              href="#projects"
              className={
                activeLink === 'projects' ? 'active navbar-link' : 'navbar-link'
              }
              onClick={() => onUpdateActiveLink('projects')}
            >
              Projects
            </Nav.Link>
            {/* <Nav.Link
              href="#hireme"
              className={
                activeLink === 'hireme' ? 'active navbar-link' : 'navbar-link'
              }
              onClick={() => onUpdateActiveLink('hireme')}
            >
              Hire Me
            </Nav.Link> */}
            <Nav.Link
              href="#partners"
              className={
                activeLink === 'partners' ? 'active navbar-link' : 'navbar-link'
              }
              onClick={() => onUpdateActiveLink('partners')}
            >
              Partners
            </Nav.Link>
          </Nav>
          <span className="navbar-text">
            <div className="social-icon">
              <a href="https://www.linkedin.com/in/amandaaay/">
                <img src={linkedinIcon} alt="" />
              </a>
              <a href="https://github.com/amandaay">
                <img src={github} alt="" />
              </a>
              <a href="https://www.instagram.com/amanda.aysm/">
                <img src={instagramIcon} alt="" />
              </a>
            </div>
            <button className="vvd" onClick={() => onUpdateActiveLink('connect')}>
              <span>Let's Connect</span>
            </button>
          </span>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
};
